import React from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import withTitle from '../shared/HOCs/withTitle';
import SignIn from '../components/SingIn';
import { LOGIN_MUTATION } from '../queries/auth/account';
import { TOKEN_NAME } from '../shared/constants/settings';

const SignInContainer = () => {
  const history = useHistory();
  const client = useApolloClient();

  const login = (token, refreshExpiresIn) => {
    localStorage.setItem(TOKEN_NAME, token);
    localStorage.setItem('refreshExpiresIn', refreshExpiresIn);
    history.push('/app/email-digests');
  };

  const [loginUser] = useMutation(LOGIN_MUTATION, {
    onCompleted: data => {
      const { token, refreshExpiresIn } = data.tokenAuth;
      if (token) {
        login(token, refreshExpiresIn);
      }
    },
    onError: () => {
      client.clearStore();
      window.localStorage.clear();
      // window.location.href = '/accounts/login';
    },
  });

  const onSubmit = data => {
    loginUser({
      variables: {
        email: data.email,
        password: data.password,
      },
    });
  };

  return (
    <SignIn
      onSubmit={onSubmit}
    />
  );
};

export default withTitle('Stream Admin pannel')(SignInContainer);

import React from 'react';
import { useQuery } from '@apollo/client';
import DigestsList from '../../components/DigestsList';
import { ALL_DIGESTS_QUERY } from '../../../../queries/emailDigest/queries';
import useDigestActions from '../../hooks/useDigestActions';
import { DEFAULT_SUBJECT } from '../../../../shared/constants/constants';
import withTitle from '../../../../shared/HOCs/withTitle';

const AlphaSenseEmailDigests = () => {
  const { createDigest } = useDigestActions();

  const { data: { allEmailDigests } = {}, fetchMore } = useQuery(ALL_DIGESTS_QUERY, {
    variables: {
      first: 10,
      skip: 0,
      platform: 'as',
    },
  });

  const handleDigestCreate = () => {
    createDigest({
      variables: {
        subject: DEFAULT_SUBJECT,
        templates: 'transcript_digest',
        body: '<p><br></p>',
        platform: 'as',
      },
    });
  };

  return (
    <DigestsList
      title="AlphaSense Email Digest"
      urlPrefix="as-"
      allEmailDigests={allEmailDigests}
      fetchMore={fetchMore}
      handleDigestCreate={handleDigestCreate}
    />
  );
};

export default withTitle('AlphaSense Email Digest')(AlphaSenseEmailDigests);

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar } from '@material-ui/core';
import mainStyles from './styles';
import Header from './partials/Header';

const Public = ({ children }) => {
  const classes = mainStyles();
  return (
    <Box className={classes.root}>
      <Header />
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </Box>
  );
};

Public.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Public;

import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ORDER_OPTIONS } from '../../../../../../shared/constants/constants';

const Ordering = ({ ordering, setOrdering }) => {
  const handleChange = event => {
    setOrdering(event.target.value);
  };
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={ordering}
      onChange={handleChange}
      fullWidth
    >
      {ORDER_OPTIONS.map(el => <MenuItem key={el.key} value={el}>{el.value}</MenuItem>)}
    </Select>
  );
};

Ordering.propTypes = {
  ordering: PropTypes.string.isRequired,
  setOrdering: PropTypes.func.isRequired,
};

export default Ordering;

import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TOKEN_NAME } from '../constants/settings';

const RouteWithLayout = ({
  layout: Layout,
  component: Component,
  loginRequired = true,
  ...rest
}) => {
  const token = localStorage.getItem(TOKEN_NAME) || '';
  if (!token && loginRequired) {
    const locationParams = `${window.location.pathname}${window.location.search}`;
    window.location.href = `/account/login/?next=${locationParams}`;
    return null;
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  layout: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
  loginRequired: PropTypes.bool.isRequired,
};

export default RouteWithLayout;

import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => createStyles({
  cardHeader: {
    padding: '10px 16px',
    '& .MuiCardHeader-action': {
      margin: 0,
      alignSelf: 'center',
    },
  },
  list: {
    width: '100%',
    minHeight: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  outRect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px 18px 16px 28px',
    height: '18px',
  },
  inRect: {
    backgroundColor: theme.palette.info.main,
    borderRadius: '5px',
  },
  inRectM: {
    backgroundColor: theme.palette.info.main,
    borderRadius: '5px',
    marginRight: '26px',
  },
}));

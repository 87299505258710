import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
// import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// import temaplateListStyles from './styles';

const TemplateList = ({ selectedTemplates, setSelectedTemplates, register }) => {
  // const classes = temaplateListStyles();
  const { digestUID } = useParams();

  const templates = [
    { value: 'transcript_digest', label: 'Transcript Digest' },
    { value: 'transcript_digest_new', label: 'New Transcript Digest' },
    { value: 'weekly_digest_new', label: 'New Weekly Digest' },
  ];

  const handleChange = event => {
    setSelectedTemplates({ ...selectedTemplates, [event.target.name]: event.target.checked });
  };

  return (
    <Box display="flex" flexDirection="column">
      {templates.map(template => (
        <Box key={template.value} flexDirection="row" display="flex" alignItems="baseline">
          <FormControlLabel
            inputRef={register}
            control={(
              <Checkbox
                checked={Boolean(selectedTemplates[template.value])}
                onChange={handleChange}
                name={template.value}
                color="primary"
              />
            )}
            label={template.label}
          />
          <Link
            href={`${process.env.REACT_APP_SERVER_URL}/mail/digest/${digestUID}/${template.value}`}
            target="_blank"
          >
            <OpenInNewIcon />
          </Link>
        </Box>
      ))}
    </Box>
  );
};

TemplateList.propTypes = {
  selectedTemplates: PropTypes.string.isRequired,
  setSelectedTemplates: PropTypes.func.isRequired,
  register: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

export default TemplateList;

const cmp = (a, b) => {
  // dict for priorities to understand how to sort
  const priority = {
    Great: 3,
    Good: 2,
    Average: 1,
    Bad: 0,
  };
  // nulls sort after anything else
  if (a.callQuality === null) {
    return 1;
  } else if (b.callQuality === null) {
    return -1;
  } else if (priority[a.callQuality] === priority[b.callQuality]) {
    return 0;
  }
  // if descending, highest sorts first
  return priority[a.callQuality] < priority[b.callQuality] ? 1 : -1;
  // just reverse array for asc
};

export default cmp;

import React from 'react';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar,
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import sidebarStyles from './styles';

const LinkBehavior = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RouterLink ref={ref} {...props} />
));

const Sidebar = () => {
  const classes = sidebarStyles();

  const streamSelected = Boolean(useRouteMatch([
    '/app/email-digests/',
    '/app/email-digest/',
    '/app/email-digest/:digestUID',
  ]));
  const asSelected = Boolean(useRouteMatch([
    '/app/as-email-digests/',
    '/app/as-email-digest/',
    '/app/as-email-digest/:digestUID',
  ]));

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          <ListItem selected={streamSelected} component={LinkBehavior} to="/app/email-digests/">
            <ListItemIcon><MailIcon /></ListItemIcon>
            <ListItemText primary="Legacy Stream Email Digests" />
          </ListItem>
          <ListItem selected={asSelected} component={LinkBehavior} to="/app/as-email-digests/">
            <ListItemIcon><MailIcon /></ListItemIcon>
            <ListItemText primary="AlphaSense Email Digests" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;

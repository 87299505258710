import { createMuiTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  typography,
  overrides,
  spacing: 10,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;

import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { SAVE_DIGEST_MUTATION, DELETE_DIGEST_MUTATION, SEND_DIGEST_MUTAION } from '../../../queries/emailDigest/mutations';
import { ALL_DIGESTS_QUERY, DIGEST_QUREY } from '../../../queries/emailDigest/queries';

const useDigestActions = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { digestUID } = useParams();
  const asDigest = Boolean(useRouteMatch([
    '/app/as-email-digests/',
    '/app/as-email-digest/',
    '/app/as-email-digest/:digestUID',
  ]));

  const urlPrefix = asDigest ? 'as-' : '';

  const getSelectedTemplatesString = data => {
    const cpyForm = { ...data };
    delete cpyForm.body;
    delete cpyForm.interviews;
    delete cpyForm.subject;
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(cpyForm)) {
      if (value === false) {
        delete cpyForm[key];
      }
    }
    return Object.keys(cpyForm).toString();
  };

  const [createDigest, { loading: createDigestSaving }] = useMutation(SAVE_DIGEST_MUTATION, {
    onCompleted: ({ saveDigest }) => {
      const digestLink = saveDigest?.digest?.uid;
      console.log(digestLink);
      if (digestLink) {
        history.push(`/app/${urlPrefix}email-digest/${digestLink}`);
      }
    },
    onError: error => {
      console.log('digest creation failed:(');
      console.log(error);
    },
    refetchQueries: [
      { query: ALL_DIGESTS_QUERY, variables: { first: 10, skip: 0 } },
    ],
  });

  const [deleteDigest] = useMutation(DELETE_DIGEST_MUTATION, {
    variables: {
      digestUid: digestUID,
    },
    onCompleted: data => {
      if (data.deleteDigest.uid) {
        history.push(`/app/${urlPrefix}email-digests`);
        history.go(0);
        enqueueSnackbar('Digest deleted.',
          { variant: 'success' });
      }
    },
    onError: error => {
      enqueueSnackbar(error,
        { variant: 'error' });
    },
  });

  const [sendDigest] = useMutation(SEND_DIGEST_MUTAION, {
    variables: {
      digestUid: digestUID,
    },
    onCompleted: () => {
      enqueueSnackbar('Digest sent.',
        { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(error,
        { variant: 'error' });
    },
  });

  const [saveDigestMutation, { loading: saveDigestSaving }] = useMutation(SAVE_DIGEST_MUTATION, {
    variables: {
      digestUid: digestUID,
    },
    onError: error => {
      console.log('digest creation failed:(');
      console.log(error);
    },
    refetchQueries: [
      { query: ALL_DIGESTS_QUERY, variables: { first: 10, skip: 0 } },
      { query: DIGEST_QUREY, variables: { uid: digestUID } },
    ],
  });

  const handleDigestSave = formData => {
    if (getSelectedTemplatesString(formData)) {
      const interviews = formData.interviews.split(',');
      saveDigestMutation({
        variables: {
          digestUid: digestUID,
          subject: formData.subject.trim(),
          body: formData.body,
          interviewIds: interviews.length > 1 ? interviews : [],
          templates: getSelectedTemplatesString(formData),
        },
      }).then(({ data }) => {
        if (!digestUID) {
          history.push(`/app/email-digest/${data.saveDigest.digest.uid}`);
        }
        enqueueSnackbar('Digest saved.',
          { variant: 'success' });
      }).catch(err => {
        enqueueSnackbar(err,
          { variant: 'error' });
      });
    } else {
      enqueueSnackbar('Please, select at least one template',
        { variant: 'error' });
    }
  };

  const isProcessingDigest = createDigestSaving || saveDigestSaving;

  return {
    createDigest,
    deleteDigest,
    sendDigest,
    isProcessingDigest,
    handleDigestSave,
  };
};

export default useDigestActions;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import SortableComponent from './SortableList';
import itemsListStyles from './styles';
import ItemsSkeleton from './ItemsSkeleton';
import cmp from '../../../../../../shared/helpers/orderingHelpers';

const OrderableItemsColumn = ({
  title, items, checked, handleChecked, handleToggleAll, numberOfChecked, handleToggle,
  handleCheckedIcon: HandleCheckedIcon, loading, ordering, setValue,
}) => {
  const classes = itemsListStyles();
  const [interviewsCopy, setInterviewsCopy] = useState([]);

  const sortArray = () => {
    if (items && items.length > 0) {
      const sorted = ordering.key === 'ASC' ? [...items].sort(cmp).reverse() : [...items].sort(cmp);
      if (ordering) {
        setInterviewsCopy(sorted);
      }
    }
  };

  useEffect(() => {
    switch (ordering.key) {
      case 'TopContent':
        setInterviewsCopy([...items].sort((a, b) => b.engagementScore - a.engagementScore));
        break;
      case 'ASC':
      case 'DESC':
        sortArray();
        break;
      default:
        setInterviewsCopy(items);
    }
  }, [ordering, items]);

  useEffect(() => {
    setValue('interviews', interviewsCopy.map(item => item.id).join());
  }, [interviewsCopy]);

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={(
          <Checkbox
            onClick={handleToggleAll}
            checked={numberOfChecked === items.length && items.length !== 0}
            indeterminate={numberOfChecked !== items.length && numberOfChecked !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        )}
        title={title}
        subheader={`${numberOfChecked}/${items.length} selected`}
        action={(
          <IconButton size="small" aria-label="settings" onClick={handleChecked}>
            <HandleCheckedIcon />
          </IconButton>
        )}
      />
      <Divider />
      <Fade in={loading}>
        <Box>
          {loading && <ItemsSkeleton />}
        </Box>
      </Fade>
      <Fade in={!loading}>
        <List className={classes.list} dense component="div" role="list">
          <SortableComponent
            setInterviewsCopy={setInterviewsCopy}
            interviewsCopy={interviewsCopy}
            handleToggle={handleToggle}
            checked={checked}
          />
        </List>
      </Fade>
    </Card>
  );
};

OrderableItemsColumn.defaultProps = {
  items: [],
  ordering: '',
};

OrderableItemsColumn.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    interviewId: PropTypes.string,
    id: PropTypes.string,
    relevantRole: PropTypes.string,
  }).isRequired),
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChecked: PropTypes.func.isRequired,
  handleToggleAll: PropTypes.func.isRequired,
  numberOfChecked: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleCheckedIcon: PropTypes.elementType.isRequired,
  loading: PropTypes.bool.isRequired,
  ordering: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

export default OrderableItemsColumn;

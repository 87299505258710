import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import ItemsColumn from './ItemsColumn';
import OrderableItemsColumn from './OrderableItemsColumn';
import itemsListStyles from './styles';
import Ordering from '../Ordering';
import DatePicker from '../DatePicker';
import { ORDER_OPTIONS } from '../../../../../../shared/constants/constants';
import useDigestInterviewsActions from '../../../../hooks/useDigestInterviewsActions';

const ItemsList = ({
  interviewsList, interviewsData, digestUid, addIntreviwes,
  setValue, setPublishedAtFrom, setPublishedAtTo, rightLoading,
  leftLoading, fetchMore, totalCount,
}) => {
  const classes = itemsListStyles();
  const [ordering, setOrdering] = useState(ORDER_OPTIONS[0]);

  const {
    state: stateLeft,
    numberOfChecked: numberOfCheckedLeft,
    handleToggle: handleToggleLeft,
    handleToggleAll: handleToggleAllLeft,
    handleRemove: handleRemoveLeft,
    handleUpdate: handleUpdateLeft,
    handleAdd: handleAddLeft,
  } = useDigestInterviewsActions(interviewsList);

  const leftIds = stateLeft.interviews.map(el => el.id);
  const rightIntreviews = () => interviewsData.filter(el => !leftIds.includes(el.id));

  const {
    state: stateRight,
    numberOfChecked: numberOfCheckedRight,
    handleToggle: handleToggleRight,
    handleToggleAll: handleToggleAllRight,
    handleUpdate: handleUpdateRight,
    clearChecked: clearCheckedRight,
  } = useDigestInterviewsActions(rightIntreviews());

  useEffect(() => {
    handleUpdateLeft(interviewsList);
  }, [interviewsList]);

  useEffect(() => {
    handleUpdateRight(rightIntreviews());
  }, [interviewsData]);

  useEffect(() => {
    setValue('interviews', stateLeft.interviews.map(item => item.id).join());
  }, [stateLeft.interviews]);

  const addToDigest = () => {
    handleAddLeft(stateRight.checked);
    clearCheckedRight();
  };

  return (
    <Grid container spacing={1} justify="center" className={classes.root}>
      <Grid item xs>
        <Box mb={2}>
          <Ordering ordering={ordering} setOrdering={setOrdering} />
        </Box>
        <OrderableItemsColumn
          title="Selected for digest"
          items={stateLeft.interviews}
          checked={stateLeft.checked}
          ordering={ordering}
          numberOfChecked={numberOfCheckedLeft}
          handleChecked={handleRemoveLeft}
          handleCheckedIcon={RemoveIcon}
          handleToggleAll={handleToggleAllLeft}
          handleToggle={handleToggleLeft}
          addIntreviwes={addIntreviwes}
          digestUid={digestUid}
          loading={leftLoading}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs>
        <Box mb={2}>
          <DatePicker
            setPublishedAtFrom={setPublishedAtFrom}
            setPublishedAtTo={setPublishedAtTo}
          />
        </Box>
        <ItemsColumn
          title="Interviews"
          items={rightIntreviews()}
          checked={stateRight.checked}
          handleChecked={addToDigest}
          handleCheckedIcon={AddIcon}
          numberOfChecked={numberOfCheckedRight}
          handleToggleAll={handleToggleAllRight}
          handleToggle={handleToggleRight}
          loading={rightLoading}
          isSortable={Boolean(false)}
          fetchMore={fetchMore}
          totalCount={totalCount}
        />
      </Grid>
    </Grid>
  );
};

ItemsList.defaultProps = {
  interviewsList: [],
  interviewsData: [],
  digestUid: '',
};

ItemsList.propTypes = {
  interviewsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    interviewId: PropTypes.string,
    callDate: PropTypes.string,
    relevantRole: PropTypes.string,
    callQuality: PropTypes.string,
  })),
  interviewsData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    interviewId: PropTypes.string,
    callDate: PropTypes.string,
    relevantRole: PropTypes.string,
    callQuality: PropTypes.string,
  })),
  digestUid: PropTypes.string,
  addIntreviwes: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  setPublishedAtFrom: PropTypes.func.isRequired,
  setPublishedAtTo: PropTypes.func.isRequired,
  rightLoading: PropTypes.bool.isRequired,
  leftLoading: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default ItemsList;

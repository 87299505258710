import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import itemsSkeletonStyles from './styles';

const ItemsSkeleton = () => {
  const classes = itemsSkeletonStyles();

  return (
    <Box display="flex" flexDirection="column" p="8px 0">
      <Box className={classes.outRect}>
        <Box display="flex" alignItems="center">
          <Skeleton className={classes.inRectM} variant="rect" width="18px" height="18px" />
          <Skeleton className={classes.inRect} variant="rect" width="220px" height="18px" />
        </Box>
        <Skeleton className={classes.inRect} variant="rect" width="18px" height="18px" />
      </Box>
      <Box className={classes.outRect}>
        <Box display="flex" alignItems="center">
          <Skeleton className={classes.inRectM} variant="rect" width="18px" height="18px" />
          <Skeleton className={classes.inRect} variant="rect" width="220px" height="18px" />
        </Box>
        <Skeleton className={classes.inRect} variant="rect" width="18px" height="18px" />
      </Box>
      <Box className={classes.outRect}>
        <Box display="flex" alignItems="center">
          <Skeleton className={classes.inRectM} variant="rect" width="18px" height="18px" />
          <Skeleton className={classes.inRect} variant="rect" width="220px" height="18px" />
        </Box>
        <Skeleton className={classes.inRect} variant="rect" width="18px" height="18px" />
      </Box>
      <Box className={classes.outRect}>
        <Box display="flex" alignItems="center">
          <Skeleton className={classes.inRectM} variant="rect" width="18px" height="18px" />
          <Skeleton className={classes.inRect} variant="rect" width="220px" height="18px" />
        </Box>
        <Skeleton className={classes.inRect} variant="rect" width="18px" height="18px" />
      </Box>
      <Box className={classes.outRect}>
        <Box display="flex" alignItems="center">
          <Skeleton className={classes.inRectM} variant="rect" width="18px" height="18px" />
          <Skeleton className={classes.inRect} variant="rect" width="220px" height="18px" />
        </Box>
        <Skeleton className={classes.inRect} variant="rect" width="18px" height="18px" />
      </Box>
      <Box className={classes.outRect}>
        <Box display="flex" alignItems="center">
          <Skeleton className={classes.inRectM} variant="rect" width="18px" height="18px" />
          <Skeleton className={classes.inRect} variant="rect" width="220px" height="18px" />
        </Box>
        <Skeleton className={classes.inRect} variant="rect" width="18px" height="18px" />
      </Box>
    </Box>
  );
};

export default ItemsSkeleton;

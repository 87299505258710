import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import mainStyles from './styles';
import Header from './partials/Header';
import Sidebar from './partials/Sidebar';

const Main = ({ children }) => {
  const classes = mainStyles();
  return (
    <Box className={classes.root}>
      <Header />
      <Sidebar />
      <main className={classes.content}>
        {children}
      </main>
    </Box>
  );
};

export default Main;

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TablePagination } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { formatDate } from '../../../shared/helpers/dateHelpers';

const DigestsList = ({
  title, allEmailDigests, fetchMore, handleDigestCreate, urlPrefix,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: newPage * rowsPerPage,
      },
    });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = allEmailDigests?.results || [];
  const totalCount = allEmailDigests?.totalCount || 0;

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Typography variant="h3">{title}</Typography>
        <Button variant="contained" color="primary" onClick={handleDigestCreate}>
          Create new digest
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="left">Subject</TableCell>
              <TableCell align="left">Created At</TableCell>
              <TableCell align="left">Sent At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.map(row => (
              <TableRow key={row?.id}>
                <TableCell component="th" scope="row">
                  {row
                  && <Link to={`/app/${urlPrefix}email-digest/${row.uid}`}>{row.id}</Link>}
                </TableCell>
                <TableCell align="left">{row?.subject.length > 50 ? `${row?.subject.substring(0, 50)}...` : row?.subject }</TableCell>
                <TableCell align="left">{formatDate(row?.createdAt)}</TableCell>
                <TableCell align="left">{formatDate(row?.sentAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

DigestsList.defaultProps = {
  allEmailDigests: {},
  urlPrefix: '',
};

DigestsList.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allEmailDigests: PropTypes.object,
  fetchMore: PropTypes.func.isRequired,
  handleDigestCreate: PropTypes.func.isRequired,
  urlPrefix: PropTypes.string,
};

export default DigestsList;

import React from 'react';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const InterviewItem = ({ item, handleToggle, checked }) => {
  const labelId = `transfer-list-all-item-${item.id}-label`;
  return (
    <ListItem key={item.id} role="listitem" button onClick={handleToggle(item)}>
      <ListItemIcon>
        <Checkbox
          checked={checked.some(el => el.id === item.id)}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={item.relevantRole} secondary={`${item.callQuality} | ${item.perspective} | ${item.callDate}`} />
      <Link
        href={`${process.env.REACT_APP_FRONTEND_URL}/${item.interviewId}`}
        target="_blank"
      >
        <OpenInNewIcon />
      </Link>
    </ListItem>
  );
};

InterviewItem.propTypes = {
  item: PropTypes.shape({
    interviewId: PropTypes.string,
    id: PropTypes.string,
    relevantRole: PropTypes.string,
    callQuality: PropTypes.string,
    engagementScore: PropTypes.number,
    perspective: PropTypes.string,
    callDate: PropTypes.string,
  }).isRequired,
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default InterviewItem;

import React from 'react';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import InterviewItem from './InterviewItem';

const SortableInterviewItem = SortableElement(({ item, handleToggle, checked }) => (
  <InterviewItem item={item} handleToggle={handleToggle} checked={checked} />
));

SortableInterviewItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    interviewId: PropTypes.string,
    callDate: PropTypes.string,
    relevantRole: PropTypes.string,
    callQuality: PropTypes.string,
  }),
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

const SortableInterviewList = SortableContainer(({ items, handleToggle, checked }) => (
// eslint-disable-next-line  react/jsx-wrap-multilines
  <ul style={{ padding: 0, listStyle: 'none' }}>
    {items.map((item, index) => (
      <li>
        <SortableInterviewItem key={`item-${item.id}`} index={index} item={item} value={item} handleToggle={handleToggle} checked={checked} />
      </li>
    ))}
  </ul>
));

SortableInterviewList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    interviewId: PropTypes.string,
    callDate: PropTypes.string,
    relevantRole: PropTypes.string,
    callQuality: PropTypes.string,
  })),
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

const SortableComponent = ({
  interviewsCopy,
  handleToggle,
  checked,
  setInterviewsCopy,
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setInterviewsCopy(arrayMove(interviewsCopy, oldIndex, newIndex));
  };
  return (
    <SortableInterviewList
      items={interviewsCopy}
      onSortEnd={onSortEnd}
      handleToggle={handleToggle}
      checked={checked}
    />
  );
};

SortableComponent.propTypes = {
  interviewsCopy: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    interviewId: PropTypes.string,
    callDate: PropTypes.string,
    relevantRole: PropTypes.string,
    callQuality: PropTypes.string,
  })).isRequired,
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  setInterviewsCopy: PropTypes.func.isRequired,
};

export default SortableComponent;

import React from 'react';
import Box from '@material-ui/core/Box';
import saveStyles from './styles';

const SavingIndicator = () => {
  const classes = saveStyles();
  return (
    <Box className={classes.flicker}>Saving...</Box>
  );
};

export default SavingIndicator;

import React from 'react';
import Box from '@material-ui/core/Box';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useQuery } from '@apollo/client';
import { ME_QUERY } from '../../queries/auth/account';

const Profile = () => {
  const { data = {} } = useQuery(ME_QUERY);
  const me = data.me || {};

  return (
    <Box display="flex" alignItems="center" mr="8px">
      {me.nickname
      && (
        <>
          <AccountCircleIcon />
          <Box ml="4px">{me.nickname}</Box>
        </>
      )}
    </Box>
  );
};

export default Profile;

import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!){
    tokenAuth(email: $email, password: $password){
      payload,
      token,
      refreshExpiresIn
    }
  }
`;

export const ME_QUERY = gql`
  {
    me {
      id
      uuid
      isStaff
      isSuperuser
      email
      firstName
      lastName
      nickname
    }
  }
`;

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { DateRangePicker, DateRangeDelimiter } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import isValid from 'date-fns/isValid';
import { dateFormat } from '../../../../../../shared/constants/dateFormats';
import { formatIsoDate } from '../../../../../../shared/helpers/dateHelpers';

const DatePickerField = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      height: '30px',
      // maxWidth: '112px',
      '& input': {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        height: '100%',
        color: theme.palette.text.primary,
        padding: '5px',
      },
      '& fieldset': {
        borderColor: theme.palette.info.main,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderWidth: '1px',
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },
}))(TextField);

const DatePicker = ({ setPublishedAtFrom, setPublishedAtTo }) => {
  const [state, setState] = useState([null, null]);

  const handleDateChange = date => {
    setState(date);
    setPublishedAtFrom(isValid(date[0]) ? formatIsoDate(date[0]) : null);
    setPublishedAtTo(isValid(date[1]) ? formatIsoDate(date[1]) : null);
  };

  return (
    <DateRangePicker
      inputFormat={dateFormat}
      value={state}
      onChange={date => handleDateChange(date)}
      mask=""
      renderInput={(startProps, endProps) => (
        <>
          <DatePickerField
            {...startProps}
            helperText=""
            label=""
            placeholder="From"
            fullWidth
          />
          <DateRangeDelimiter>-</DateRangeDelimiter>
          <DatePickerField
            {...endProps}
            helperText=""
            label=""
            placeholder="To"
            fullWidth
          />
        </>
      )}
    />
  );
};

DatePicker.propTypes = {
  setPublishedAtFrom: PropTypes.func.isRequired,
  setPublishedAtTo: PropTypes.func.isRequired,
};

export default DatePicker;

import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DIGEST_QUREY } from '../../../queries/emailDigest/queries';
import {
  ADD_INTERVIEWS_TO_DIGEST_MUTATION,
  DELETE_INTERVIEWS_DIGEST_MUTATION,
} from '../../../queries/emailDigest/mutations';

const useInterviewActions = () => {
  const { digestUID } = useParams();

  const [addInterviews, { loading: addInterviewSaving }] = useMutation(
    ADD_INTERVIEWS_TO_DIGEST_MUTATION, {
      refetchQueries: () => [
        { query: DIGEST_QUREY, variables: { uid: digestUID } },
      ],
    },
  );

  const [deleteInterview, { loading: deleteInterviewSaving }] = useMutation(
    DELETE_INTERVIEWS_DIGEST_MUTATION, {
      refetchQueries: () => [
        { query: DIGEST_QUREY, variables: { uid: digestUID } },
      ],
    },
  );

  const isProcessingInterview = addInterviewSaving || deleteInterviewSaving;

  return {
    addInterviews,
    deleteInterview,
    isProcessingInterview,
  };
};

export default useInterviewActions;

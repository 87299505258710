import firebase from 'firebase';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';
import { Switch, BrowserRouter } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'; // choose your lib
import { LocalizationProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';

import { UserStateProvider } from './contexts/UserContext';
import Account from './components/Account';
import Forbidden from './components/Forbidden';
import Page404 from './components/Page404';
import RouterWithLayout from './shared/HOCs/RouterWithLayout';

import SignInContainer from './containers/SignInContainer';

import EmailDigest from './apps/emailDigest/pages/EmailDigest';
import EmailDigests from './apps/emailDigest/pages/EmailDigests';
import AlphaSenseEmailDigests from './apps/emailDigest/pages/AlphaSenseEmailDigests';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/performance';
import 'firebase/firestore';

import Header from './shared/components/Header';
import MainLayout from './layouts/Main';
import PublicLayout from './layouts/Public';
import client from './client';
import theme from './theme';

import './App.css';
import DashboardContainer from './containers/DashboardContainer';

const firebaseConfig = {
  apiKey: 'AIzaSyAx_u4TWhHwsp-4kfkYgTA4LBwX299SHRI',
  authDomain: 'stream-b57c5.firebaseapp.com',
  projectId: 'stream-b57c5',
  storageBucket: 'stream-b57c5.appspot.com',
  messagingSenderId: '101906532733',
  appId: '1:101906532733:web:477bb6c96632989c45a414',
  measurementId: 'G-8Z2DX0KBXC',
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const App = () => (
  <ThemeProvider theme={theme}>
    <SnackbarProvider>
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <UserStateProvider>
            <BrowserRouter>
              <div className="App">
                <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                  <Header />
                  <Switch>
                    {/* public routes */}
                    <RouterWithLayout
                      path="/account/login"
                      layout={PublicLayout}
                      loginRequired={false}
                      component={SignInContainer}
                    />
                    {/* end public routes */}

                    {/* app routes */}
                    <RouterWithLayout
                      path="/app/email-digests"
                      layout={MainLayout}
                      loginRequired
                      component={EmailDigests}
                    />
                    <RouterWithLayout
                      path="/app/as-email-digests"
                      layout={MainLayout}
                      loginRequired
                      component={AlphaSenseEmailDigests}
                    />
                    <RouterWithLayout
                      exact
                      path="/app/email-digest/"
                      layout={MainLayout}
                      loginRequired
                      component={EmailDigest}
                    />
                    <RouterWithLayout
                      exact
                      path="/app/as-email-digest/"
                      layout={MainLayout}
                      loginRequired
                      component={EmailDigest}
                    />
                    <RouterWithLayout
                      exact
                      path="/app/email-digest/:digestUID"
                      layout={MainLayout}
                      loginRequired
                      component={EmailDigest}
                    />
                    <RouterWithLayout
                      exact
                      path="/app/as-email-digest/:digestUID"
                      layout={MainLayout}
                      loginRequired
                      component={EmailDigest}
                    />
                    <RouterWithLayout
                      path="/app/account"
                      component={Account}
                      layout={MainLayout}
                      loginRequired
                    />
                    {/* end app routes */}

                    <RouterWithLayout
                      layout={PublicLayout}
                      path="/app/forbidden"
                      component={Forbidden}
                      loginRequired={false}
                    />

                    <RouterWithLayout
                      path="/"
                      layout={MainLayout}
                      component={DashboardContainer}
                      loginRequired
                    />

                    <RouterWithLayout
                      layout={PublicLayout}
                      component={Page404}
                      loginRequired={false}
                    />
                  </Switch>
                </FirebaseAppProvider>
              </div>
            </BrowserRouter>
          </UserStateProvider>
        </LocalizationProvider>
      </ApolloProvider>
    </SnackbarProvider>
  </ThemeProvider>
);

export default App;

import { gql } from '@apollo/client';
import { InterviewFragments } from './queries';

export const SAVE_DIGEST_MUTATION = gql`
  mutation saveDigestMutation($body: String, $digestUid: UUID, $subject: String!, $templates: String, $interviewIds: [Int], $platform: String){
    saveDigest(body: $body, digestUid: $digestUid, subject: $subject, templates: $templates, interviewIds: $interviewIds, platform: $platform){
      digest {
        uid
      }
    }
  }
`;

export const DELETE_DIGEST_MUTATION = gql`
  mutation deleteDigestMutation($digestUid: UUID){
    deleteDigest(digestUid: $digestUid){
      uid
    }
  }
`;

export const ADD_INTERVIEWS_TO_DIGEST_MUTATION = gql`
  mutation addInterviewsToDigest($digestUid: UUID!, $interviewIds: [Int]!){
    addInterviewToDigest(digestUid: $digestUid, interviewIds: $interviewIds){
      digest {
        interviews
        interviewsList{
          ...interviewCard
        }
      }
    }
  }
  ${InterviewFragments.card}
`;

export const DELETE_INTERVIEWS_DIGEST_MUTATION = gql`
  mutation deleteInterviewFromDigest($digestUid: UUID!, $interviewIds: [Int]!){
    deleteInterviewFromDigest(digestUid: $digestUid, interviewIds: $interviewIds){
      digest {
        interviews
        interviewsList{
          ...interviewCard
        }
      }
    }
  }
  ${InterviewFragments.card}
`;

export const SEND_DIGEST_MUTAION = gql`
  mutation sendDigest($digestUid: UUID!) {
    sendDigest(digestUid: $digestUid) {
      responce
    }
  }
`;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import itemsListStyles from './styles';
import ItemsSkeleton from './ItemsSkeleton';
import InterviewItem from './InterviewItem';

const ItemsColumn = ({
  title, items, checked, handleChecked, handleToggleAll, numberOfChecked, handleToggle,
  handleCheckedIcon: HandleCheckedIcon, loading, fetchMore, totalCount,
}) => {
  const classes = itemsListStyles();

  // i am so sorry about this
  const correctNumOfChecked = numberOfChecked >= items.length ? items.length : numberOfChecked;
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    if (!items) {
      setHasMore(false);
    } else if (items.length < totalCount) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [items, totalCount]);

  useEffect(() => {
    console.log('has more', items.length, totalCount, hasMore);
  }, [hasMore]);

  const loadMore = () => {
    fetchMore({
      variables: {
        skip: items.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (!prev) {
          return {
            allInterviews: fetchMoreResult.allInterviews,
          };
        }
        return {
          allInterviews: {
            ...prev.allInterviews,
            results: [...prev.allInterviews.results, ...fetchMoreResult.allInterviews.results],
          },
        };
      },
    });
  };

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={(
          <Checkbox
            onClick={handleToggleAll}
            checked={numberOfChecked === items.length && items.length !== 0}
            indeterminate={numberOfChecked !== items.length && numberOfChecked !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        )}
        title={title}
        subheader={`${correctNumOfChecked}/${items.length} selected`}
        action={(
          <IconButton size="small" aria-label="settings" onClick={handleChecked}>
            <HandleCheckedIcon />
          </IconButton>
        )}
      />
      <Divider />
      <Fade in={loading}>
        <Box>
          {loading && <ItemsSkeleton />}
        </Box>
      </Fade>
      <Fade in={!loading}>
        <List className={classes.list} dense component="div" role="list">
          {/* <Box> */}
          <InfiniteScroll
            pageStart={0}
            dataLength={items && items.length}
            next={loadMore}
            hasMore={hasMore}
            loader={<ItemsSkeleton />}
            // scrollableTarget="sidebar-content"
          >
            {items.length > 0 && items.map(item => (
              <InterviewItem item={item} handleToggle={handleToggle} checked={checked} />
            ))}
          </InfiniteScroll>
          {/* old realization */}
          {/* {items.length > 0 && items.map(item => (
            <InterviewItem item={item} handleToggle={handleToggle} checked={checked} />
          ))} */}
        </List>
        {/* </Box> */}
      </Fade>
    </Card>
  );
};

ItemsColumn.defaultProps = {
  items: [],
};

ItemsColumn.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    interviewId: PropTypes.string,
    id: PropTypes.string,
    relevantRole: PropTypes.string,
  }).isRequired),
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChecked: PropTypes.func.isRequired,
  handleToggleAll: PropTypes.func.isRequired,
  numberOfChecked: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleCheckedIcon: PropTypes.elementType.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default ItemsColumn;

import uniqBy from 'lodash/uniqBy';
import { useSafeSetState } from '../../../shared/hooks';

const useDigestInterviewsActions = leftItems => {
  const INIT_STATE = {
    checked: [],
    interviews: leftItems,
  };

  const [state, safeSetState] = useSafeSetState(
    INIT_STATE,
     (state, newState) => ({ ...state, ...newState }), // eslint-disable-line
  );

  const clearChecked = () => {
    safeSetState({ checked: [] });
  };

  const handleUpdate = items => {
    safeSetState({ interviews: items });
  };

  const handleAdd = items => {
    const newInterviews = uniqBy([...state.interviews, ...items], 'id');
    safeSetState({ interviews: newInterviews });
  };

  const handleRemove = () => {
    const checkedIds = state.checked.map(el => el.id);
    const updatedIntreviewes = state.interviews.filter(el => !checkedIds.includes(el.id));
    safeSetState({ interviews: updatedIntreviewes });
    clearChecked();
  };

  const handleToggleAll = () => {
    if (state.checked.length !== state.interviews.length) {
      safeSetState({ checked: state.interviews.map(el => el) });
    } else {
      safeSetState({ checked: [] });
    }
  };

  const handleToggle = item => () => {
    const isItemChecked = state.checked.some(el => el.id === item.id);
    const newChecked = isItemChecked
      ? state.checked.filter(el => el.id !== item.id)
      : [...state.checked, item];
    safeSetState({ checked: newChecked });
  };

  return {
    handleUpdate,
    handleAdd,
    clearChecked,
    handleRemove,
    handleToggleAll,
    handleToggle,
    numberOfChecked: state.checked.length,
    state,
  };
};

export default useDigestInterviewsActions;

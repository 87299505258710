import { gql } from '@apollo/client';

export const InterviewFragments = {
  card: gql`
    fragment interviewCard on InterviewType {
      id
      interviewId
      callDate
      relevantRole
      callQuality
      engagementScore
      perspective
    }
  `,
};

export const ALL_DIGESTS_QUERY = gql`
  query allEmailDigests ($first: Int, $skip: Int, $platform: String){
    allEmailDigests (first: $first, skip: $skip, platform: $platform){
      totalCount
      results {
        id
        uid
        createdAt
        subject
        sentAt
      }
    }
  }
`;

export const DIGEST_QUREY = gql`
  query emailDigest($uid: String) {
    emailDigest(uid: $uid) {
      uid
      status
      interviews
      templates
      interviewsList{
        ...interviewCard
      }
      body
      subject
    }
  }
  ${InterviewFragments.card}
`;

export const ALL_INTERVIEWS_DIGEST = gql`
  query allInterviewsDigest($publishedAtFrom: Date, $publishedAtTo: Date, $first: Int, $skip: Int) {
    allInterviews(publishedAtFrom: $publishedAtFrom, publishedAtTo: $publishedAtTo, first: $first, skip: $skip){
      totalCount
      results{
        ...interviewCard
      }
    }
  }
  ${InterviewFragments.card}
`;

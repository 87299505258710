import React, {
  createContext, useReducer, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { ME_QUERY } from '../queries/auth/account';
import actions from '../actions';

const UserStateContext = createContext({});

const initialData = {
  user: undefined,
};

const userStateReducer = (state, action) => {
  switch (action.type) {
    case actions.user.SET_USER:
      return { ...state, user: action.payload };
    case action.user.UNSET_USER:
      return { ...state, user: initialData.user };
    default:
      throw new Error(`Action type \`${action.type}\` does not exists on userStateReducer`);
  }
};

export const UserStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userStateReducer, initialData);
  const [loadUser, { data: userData }] = useLazyQuery(ME_QUERY);

  useEffect(() => {
    if (!state.user) {
      loadUser();
    }
  }, []);

  useEffect(() => {
    dispatch({ type: actions.user.SET_USER, payload: userData });
  }, [userData]);

  return (
    <UserStateContext.Provider value={{ state, dispatch }}>
      {children}
    </UserStateContext.Provider>
  );
};

UserStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUserState = () => useContext(UserStateContext);


import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';

import { TOKEN_NAME } from './shared/constants/settings';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN_NAME) || '';

  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

// eslint-disable-next-line no-unused-vars
const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log(graphQLErrors, networkError);
  const token = localStorage.getItem(TOKEN_NAME) || '';
  if (graphQLErrors) {
    const criticalErrors = [
      'Error decoding signature',
      'Signature has expired',
      'User is disabled',
    ];
    // eslint-disable-next-line no-unused-vars
    graphQLErrors.forEach(({ message }) => {
      if (criticalErrors.includes(message)) {
        window.localStorage.clear();
        const locationParams = `${window.location.pathname}${window.location.search}`;
        window.location.href = `/account/login/?next=${locationParams}`;
      }
    });
    Sentry.captureException(JSON.stringify({ token, graphQLErrors }));
  }
  if (networkError) {
    Sentry.captureException(JSON.stringify({ token, networkError }));
  }
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
});

const sentryLink = new SentryLink();

const client = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  link: from([sentryLink, authLink, errorLink, uploadLink]),
  cache: new InMemoryCache(),
});

export default client;

import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const Title = ({ children }) => {
  const defaultTitle = 'Your Company';

  return (
    <Helmet>
      <title>
        {children || defaultTitle}
        {' '}
        | Stream Admin pannel
      </title>
    </Helmet>
  );
};

const withTitle = (title = 'Stream Admin pannel') => ComposedComponent => props => (
  <div>
    <Title>{title}</Title>
    <ComposedComponent {...props} />
  </div>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withTitle;

import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useApolloClient } from '@apollo/client';
import headerStyles from './styles';
import Profile from '../../../../components/Profile';

const Header = () => {
  const classes = headerStyles();
  const client = useApolloClient();

  const handleLogOut = () => {
    setTimeout(() => {
      client.clearStore();
      window.localStorage.clear();
      window.location.href = '/account/login';
    }, 300);
  };
  return (
    <>
      <AppBar position="fixed" className={classes.appBar} color="primary">
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" noWrap>
            <img alt="LogoIcon" src="/logo_icon_dark.svg" />
          </Typography>
          <Box display="flex" alignItems="center">
            <Profile />
            <Button variant="contained" color="secondary" size="small" onClick={handleLogOut}>logout</Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>

  );
};

export default Header;

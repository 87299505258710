import React, { useEffect, useState } from 'react';
import {
  Box, Button, Grid, TextField,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactQuill from 'react-quill';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isValid from 'date-fns/isValid';
import emailDigestStyles from './styles';
import ItemsList from './partials/ItemsList';
import 'react-quill/dist/quill.snow.css';
import TemplateList from './partials/TemplatesList';
import SavingIndicator from './partials/SavingIndicator';
import useInterviewActions from '../../hooks/useInterviewActions';
import { ALL_INTERVIEWS_DIGEST, DIGEST_QUREY } from '../../../../queries/emailDigest/queries';
import useDigestActions from '../../hooks/useDigestActions';
import { useSafeSetState } from '../../../../shared/hooks';
import { DEFAULT_SUBJECT } from '../../../../shared/constants/constants';
import { formatIsoDate } from '../../../../shared/helpers/dateHelpers';
import withTitle from '../../../../shared/HOCs/withTitle';

const EmailDigest = () => {
  const classes = emailDigestStyles();
  const { digestUID } = useParams();

  const { loading: leftLoading, data: emailDigestData = {} } = useQuery(DIGEST_QUREY, {
    skip: !digestUID,
    variables: { uid: digestUID },
  });

  const today = new Date();
  const [publishedAtFrom, setPublishedAtFrom] = useState((isValid(today) && formatIsoDate(today))
    || null);
  const [publishedAtTo, setPublishedAtTo] = useState((isValid(today) && formatIsoDate(today))
    || null);

  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  const INIT_STATE = {
    selectedTemplates: {},
    subject: DEFAULT_SUBJECT,
  };

  const [state, safeSetState] = useSafeSetState(
    INIT_STATE,
      (state, newState) => ({ ...state, ...newState }), // eslint-disable-line
  );

  useEffect(() => {
    if (isEmpty(emailDigestData)) return;

    const templatesString = emailDigestData?.emailDigest?.templates;
    const templatesArray = templatesString ? templatesString.split(',') : [];
    const templatesObject = templatesArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = true;
      return accumulator;
    }, {});

    console.log(templatesArray);
    let newSelectedTemplates = {};

    if (templatesArray) {
      if (!isEqual(templatesObject, state.selectedTemplates)) {
        newSelectedTemplates = { ...state.selectedTemplates, ...templatesObject };
        safeSetState({ ...emailDigestData.emailDigest, selectedTemplates: newSelectedTemplates });
      }
    }
  }, [emailDigestData]);

  const [getAllInterviews, {
    loading: rightLoading,
    data: { allInterviews = {} } = {},
    fetchMore,
  }] = useLazyQuery(ALL_INTERVIEWS_DIGEST, {
    variables: {
      first: 40,
      skip: 0,
      publishedAtFrom,
      publishedAtTo,
    },
  });

  useEffect(() => {
    if (!rightLoading) { getAllInterviews(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const {
    deleteDigest,
    sendDigest,
    handleDigestSave,
  } = useDigestActions();

  const { deleteInterview, addInterviews, isProcessingInterview } = useInterviewActions();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    register,
    setValue,
    handleSubmit,
  } = useForm();

  const {
    selectedTemplates, interviewsList, uid,
  } = state;

  useEffect(() => {
    if (selectedTemplates.transcript_digest && selectedTemplates.transcript_digest_new) {
      enqueueSnackbar('By selecting more than one template, you are switching to the daily digest A/B testing mode.',
        { variant: 'success' });
    }
  }, [selectedTemplates]);

  useEffect(() => {
    if (isEmpty(emailDigestData)) return;
    setValue('subject', emailDigestData.emailDigest.subject);
    setValue('body', emailDigestData?.emailDigest?.body || '');
  }, [emailDigestData]);
  return (
    <form onSubmit={handleSubmit(handleDigestSave)}>
      <Grid container spacing={1}>
        <Grid xs={12} item>
          <Box display="flex" alignItems="center" justifyContent="flex-end" mb={1}>
            {(isProcessingInterview) && <SavingIndicator />}
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              startIcon={<DeleteIcon />}
              onClick={deleteDigest}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              endIcon={<SendIcon />}
              onClick={sendDigest}
            >
              Send
            </Button>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box mb={2}>
            <Controller as={TextField} name="subject" fullWidth control={control} placeholder="Enter Digest Name" />
          </Box>
          <Box mb={2}>
            <Controller as={ReactQuill} name="body" control={control} defaultValue="" />
          </Box>
          <input type="hidden" name="interviews" ref={register} />
          <Box mb={2}>
            <TemplateList
              register={register}
              selectedTemplates={selectedTemplates}
              setSelectedTemplates={templates => safeSetState({ selectedTemplates: templates })}
            />
          </Box>
        </Grid>
        <Grid item md={8}>
          <ItemsList
            interviewsList={interviewsList}
            interviewsData={allInterviews?.results}
            rightLoading={rightLoading}
            leftLoading={leftLoading}
            digestUid={uid}
            addIntreviwes={addInterviews}
            deleteInterview={deleteInterview}
            setPublishedAtFrom={setPublishedAtFrom}
            setPublishedAtTo={setPublishedAtTo}
            setValue={setValue}
            fetchMore={fetchMore}
            totalCount={allInterviews?.totalCount || 0}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default withTitle('Stream Admin pannel')(EmailDigest);

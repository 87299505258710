import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import headerStyles from './styles';

const Header = () => {
  const classes = headerStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" noWrap>
          <img alt="LogoIcon" src="/logo_icon_dark.svg" />
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
